import { useCallback } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { useValueRef } from 'hooks/useValueRef';
import { WindowScroll, getWindowScroll } from 'utils/viewport/getWindowScroll';

export function useScrollEffect(
  fn: (position: WindowScroll) => void,
  options: { skipBootTrigger?: boolean } = {},
) {
  const fnRef = useValueRef(fn);

  const handleScroll = useCallback(() => {
    fnRef.current(getWindowScroll());
  }, [fnRef]);

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if (!options.skipBootTrigger) handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
}
